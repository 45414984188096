@mixin content-columns{
    @include grid-column(1, 12, true);
    @include breakpoint(large up) {
        @include grid-column(2, 11, true);
    }
    @include breakpoint(xlarge up) {
        @include grid-column(2, 10, true);
    }
    @include breakpoint(xxlarge up) {
        @include grid-column(3, 8, true);
    }
}

$header-columns: (
    small: 1 12,
    460px: 2 10,
    medium: 2 8,
    large: 2 6,
    xxlarge: 3 5
);

@mixin header-columns {
    @include breakout($header-columns) {
        @include grid-column(nth($value, 1), nth($value, 2), true);
    }
    // @include grid-column(2, 11, true);
    // @include breakpoint(360px up) {
    //     @include grid-column(3, 10, true);
    // }
    // @include breakpoint(500px up) {
    //     @include grid-column(3, 9, true);
    // }
    // @include breakpoint(medium up) {
    //     @include grid-column(2, 8, true);
    // }
    // @include breakpoint(large up) {
    //     @include grid-column(2, 6, true);
    // }
    // @include breakpoint(xxlarge up) {
    //     @include grid-column(3, 4, true);
    // }
}

@mixin page-layout-base {
    .primary,
    .header-banner {
        @include grid;
        @include grid14;
    }
    .header-banner {
        position: relative;
        z-index: 2;
    }
    .primary {
        position: relative;
        z-index: 1;
        // position: relative;
        // @include breakout($banner-height) {
        //     margin-top: $value / -6;
        // }
        @include make-grid-rows(6);
        & > * {
            @include content-columns;
        }
    }
}

@mixin page-layout-default {
    .primary .group.page {
        max-width: 70ch;
        @supports (column-width: 40ch) {
            max-width: none;
            column-width: 40ch;
            column-gap: 0;
            h2.rte, h3.rte {
                break-inside: avoid;
                &::after {
                    content: '';
                    display: block;
                    height: 10rem;
                    margin-bottom: -10rem;
                }
            }
            ul {
                break-inside: avoid;
            }
        }
    }
    @include breakout($section-padding) {
        .primary > * {
            margin-top: $value;
            &:last-child {
                margin-bottom: $value;
            }
        }
    }
}

@mixin item-gutters {
    @include breakout($content-gutters) {
        margin-left: $value;
        margin-right: $value;
    }
}

@mixin contact-fab {
    @include grid;
    @include grid14;
    z-index: 28;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    pointer-events: none;
    transform: translateY(20%);
    transition: opacity .1s,
                transform .2s ease;
    @include breakpoint(medium up) {
        bottom: $baseline * 3;
    }
    @include breakpoint(xlarge up) {
        bottom: $baseline * 4;
    }
    .button {
        @include grid-row(1);
        @include header-columns();
        @include grid-justify-self(start);
        padding: .75em 1.2em;
        box-shadow: 0 (2/$em) * 1em 0 $anchor-color-hover,
                    0 2px 9px rgba(0,0,0,0.3);
        @include breakpoint(smallplus down) {
            @include grid-column(1, 14);
            border-radius: 0;
            width: 100%;
        }
    }
    &.active:not(.form-visible) {
        opacity: 1;
        transform: none;
        .button {
            pointer-events: auto;
        }
    }
}

@mixin hero-banner-height($banner-height, $tail-ratio: $banner-clip-ratio) {
    @include breakout($banner-height) {
        @if $tail-ratio {
            @include grid-template-rows(minmax(#{$value * ($tail-ratio - 1) / $tail-ratio}, 1fr));
        } @else {
            @include grid-template-rows(minmax(#{$value}, 1fr));
        }
        --banner-height: #{$value};
    }
    @if $tail-ratio {
        --tail-height: calc(var(--banner-height) / #{$tail-ratio});
    } @else {
        --tail-height: 0rem;
    }
    @include breakout($banner-height) {
        .hero-page {
            margin-top: max($value / 6, $baseline * 6);
            margin-bottom: $value / 6;
        }
    }
    @if not $banner-clip-path {
        @supports (--css: variables) {
            margin-bottom: calc(var(--tail-height) * -1);
            grid-template-rows: minmax(var(--banner-height), 1fr);
            padding-bottom: calc(var(--tail-height));
        }
    } @else {    
        @supports (--css: variables) and (clip-path: polygon(0 0, 100% 0, 100% 100%)) {
            margin-bottom: calc(var(--tail-height) * -1);
            grid-template-rows: minmax(var(--banner-height), 1fr);
            padding-bottom: calc(var(--tail-height));
        }
    }
}

@mixin hero-banner-tail {
    @include breakout($outer-gutters) {
        --outer-gutter: #{$value};
    }
    --tail-offset: 0px;
    @include breakpoint(large up) {
        --tail-offset: var(--outer-gutter);
    }
    @include breakpoint(xxlarge up) {
        // --tail-offset: calc(var(--outer-gutter) + (100% - (var(--outer-gutter) * 2)) * 1/12);
    }
    @supports (--css: variables) and (clip-path: polygon(0 0, 100% 0, 100% 100%)) {
        clip-path: $banner-clip-path;
    }
}

@mixin hero-banner($banner-height: $banner-height) {
    @include hero-banner-height($banner-height);
    @include hero-banner-tail;
    background-color: $header-background-color;
    color: $header-text-color;
    position: relative;
    pointer-events: none;
    .pager.page_image {
        @include grid-row(1);
        @include grid-column(1, 14);
        @include object-fit;
        display: flex;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: $header-background-opacity / 2;
        }
        @supports (mix-blend-mode: multiply) and (filter: grayscale(1)) {
            img {
                mix-blend-mode: $header-background-blend-mode;
                filter: $header-background-filter;
                opacity: $header-background-opacity;
                // will-change: opacity;
            }
        }
    }
    .hero-page {
        @include grid-row(1);
        @include header-columns;
        @include item-gutters;
        position: relative;
        pointer-events: auto;
    }
    .title,
    .description,
    .text {
        color: inherit;
    }
}
%slide-nav-arrow {
    height: $baseline * 2;
    width: $baseline * 2;
    margin: $baseline;
    border-top: $baseline / 3 solid;
    border-right: $baseline / 3 solid;
    transform: rotate(45deg);
    opacity: .75;
}
@mixin content-slideshow(
    $height: (
        small: (320/$em) * 1rem,
        xlarge: (400/$em) * 1rem,
        xxlarge: (480/$em) * 1rem
    )
) {
    @include slideshow($nav-button-placeholder: "%slide-nav-arrow");
    position: relative;
    .slideshow-stage {
        margin-bottom: $baseline * 3;
        @include breakpoint(medium up) {
            margin-bottom: $baseline * 3.5;
        }
    }
    .slideshow-cont,
    .slide,
    .head,
    .head-image {
        display: flex;
        flex-grow: 1;
    }
    .image {
        @include object-fit((
            object-fit: contain
        ));
        display: flex;
        background-color: $content-background-color;
        height: auto;
        flex-grow: 1;
        @include breakout($height) {
            max-height: $value;
        }
    }
    .slideshow-navigation {
        @include content-slideshow-navigation-pips;
        z-index: 5;
        position: absolute;
        left: 0;
        max-width: 70%;
        bottom: 0;
        // top: calc(100% + #{$baseline});
        // @include breakpoint(medium up) {
        //     top: calc(100% + #{$baseline * 1.5});
        // }
    }
}
@mixin content-slideshow-navigation-pips {
    pointer-events: none;
    ul {
        display: flex;
        flex-wrap: wrap;
    }
    li {
        box-sizing: content-box;
        pointer-events: auto;
        cursor: pointer;
        width: 2em;
        height: (2/$em) * 1em;
        background-color: $light-gray;
        background-clip: content-box;
        padding-top: calc(#{$baseline} - #{(1/$em) * 1rem});
        padding-bottom: calc(#{$baseline} - #{(1/$em) * 1rem});
        margin-right: (4/$em) * 1em;
        transition: background-color .3s;
        &.current-slide {
            background-color: $anchor-color;
        }
        &:hover,
        &:focus {
            background-color: $anchor-color-hover;
        }
    }
}


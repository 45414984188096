//config
@import 'site:variables';
@import 'site:icon-mixins';

//mixins
@import '@depot/utility';
@import '@depot/grid';
@import '@depot/flex-columns';
@import '@depot/breakpoint';
@import '@depot/typography';
@import 'forms:mixins';
@import 'site:page-mixins';
@import 'site:item-mixins';

@import 'slideshow:mixins';

@import 'mixins';

.lister.projects-featured-home,
.lister.projects-featured {
    @include grid-column(1, 14);
}

.lister.projects-list {
    @include grid-column(1, 12, true);
    @include projects-basic-item;
    @include breakout($section-padding) {
        padding-top: $value;
        padding-bottom: $value;
    }
    .items {
        @include flex-columns(
            $per-row: (
                small: 2,
                medium: 2,
                large: 4,
                xlarge: 4,
                // xxlarge: 5,
            ),
            $gutter: (
                small: $gutter / 2,
                medium: $gutter,
                xlarge: $gutter * 2
            ),
            $baseline: (
                small: $baseline * 1.5,
                medium: $baseline * 2
            )
        );
        margin-top: $baseline * -1.5;
        @include breakpoint(medium up) {
            margin-top: $baseline * -2;
        }
    }
}

.lister.projects-featured-home {
    @include projects-featured-item-full;
}

.lister.projects-featured {
    @include projects-featured-item;
    .items {
        @include flex-columns(
            $per-row: (
                small: 1,
                medium: 2
            ),
            $gutter: 0px,
            $baseline: 0px
        );
    }
    .item {
        flex-grow: 1;
    }
    .sb-display-point-edit-buttons {
        top: auto;
        bottom: 1px;
    }
}

.lister.project-slideshow {
    @include content-slideshow;
}

.layout-projects-page {
    @include page-layout-project;
}

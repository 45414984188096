@mixin input-uploader-colors($props: ()) {
    $props: get-input-props($props, input-colors);
    .input-uploader-choose {
        &:hover,
        &:focus {
            color: map-get($props, input-border-focus-color);
        }
    }
    .input-uploader-remove {
        border-color: map-get($props, input-border-color);
        &:hover,
        &:focus {
            color: map-get($props, input-border-alert-color);
        }
    }
}
@mixin input-uploader($props: ()) {
    $props: get-input-props($props, input-base);
    $input-padding: map-get($props, input-padding);
    $input-border: map-get($props, input-border);
    $input-border-radius: map-get($props, input-border-radius);
    $input-height: get-input-height($props);
    $input-line-height: get-input-line-height($props);

    &.input-uploader-field {
        display: flex;
    }
    
    .input-uploader-preview {
        height: $input-height;
        margin-top: get-side($input-padding, top) * -1;
        margin-bottom: get-side($input-padding, bottom) * -1;
        .icon,
        img {
            width: $input-line-height;
            height: $input-line-height;
            margin-top: get-side($input-padding, top);
            margin-bottom: get-side($input-padding, bottom);
        }
        .icon {
            fill: currentColor;
            background-image: none !important;
        }
        img {
            display: block;
            max-width: none;
        }
        .input-uploader-type-image {
            height: $input-height;
            width: $input-height;
            border-top-left-radius: $input-border-radius;
            border-bottom-left-radius: $input-border-radius;
            margin-left: get-side($input-padding, left) * -1;
            margin-top: 0;
            margin-bottom: 0;
        }
    }
    .input-uploader-choose,
    .input-uploader-remove {
        cursor: pointer;
    }
    .input-uploader-choose {
        display: flex;
        flex-grow: 1;
        overflow: hidden;
    }
    .input-uploader-remove {
        margin-left: .5em;
        padding-left: .5em;
        border-left: $input-border;
        .icon {
            width: 1em;
            height: 1em;
            fill: currentColor;
            background-image: none !important;
        }
    }
    .input-uploader-choose-text,
    .input-uploader-change-text {
        margin-left: .5em;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    //visibility
    &:not(.input-uploader-has-file) {
        .input-uploader-preview img,
        .input-uploader-change-text,
        .input-uploader-remove {
            display: none;
        }
    }
    &.input-uploader-has-file {
        .input-uploader-preview .icon,
        .input-uploader-choose-text {
            display: none;
        }
    }
}

@mixin projects-featured-item-base {
    .item {
        &::before {
            z-index: 2;
            position: relative;
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            opacity: .9;
            @supports (filter: blur(4px)) {
                opacity: .8;
            }
        }
        color: $text-color-white;
        overflow: hidden;
    }
    .item:nth-child(4n + 1) {
        &::before {
            background-color: $orange;
        }
    }
    .item:nth-child(4n + 2) {
        &::before {
            background-color: $blue;
        }
    }
    .item:nth-child(4n + 3) {
        &::before {
            background-color: $green;
        }
    }
    .item:nth-child(4n + 4) {
        &::before {
            background-color: $purple;
        }
    }
    .image {
        @include object-fit((
            object-position: center top
        ));
        position: relative;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    .image-desktop {
        z-index: 1;
        img {
            filter: blur(4px);
            transform-origin: center;
            transform: scale(1.05);
        }
    }
    .image-mobile {
        z-index: 3;
        position: relative;
        border-radius: 18px;
        // box-shadow: 0 0 0 8px $dark-gray;
        border: 8px solid $dark-gray;
        background-color: $dark-gray;
        &::before {
            content: '';
            display: block;
            width: 100%;
            padding-bottom: 200%;
        }
    }
    @include breakout($content-gutters) {
        .image-mobile,
        .text-group {
            margin-left: $value;
            margin-right: $value;
        }
    }
    .text-group {
        z-index: 3;
        position: relative;
        .title {
            @include typography(header-b);
        }
        .summary {
            margin-top: $baseline;
            max-width: 34ch;
        }
        .button {
            margin-top: $baseline * 2;
            color: $text-color-white;
            padding: .5em 0;
            border-top: 1px solid;
            border-bottom: 1px solid;
        }
        @include breakpoint(xlarge up) {
            .summary {
                margin-top: $baseline * 2;
            }
            .button {
                margin-top: $baseline * 3;
            }
        }
    }
}

@mixin projects-featured-item(
    $height: (
        small: (320/$em) * 1rem,
        xlarge: (400/$em) * 1rem,
        xxlarge: (460/$em) * 1rem
    ),
    $mobile-image-width: (
        small: 200px,
        medium: 280%,
        large: 240%
    )
) {
    @include projects-featured-item-base;
    .items {
        overflow: hidden;
    }
    .item {
        @include grid;
        @include grid14;
        @include breakout($height) {
            @include grid-template-rows(minmax(#{$value}, 1fr));
        }
        & > * {
            @include grid-row(1);
        }
        &::before {
            @include grid-row(1);
            @include grid-column(1, 14);
        }
        overflow: visible;
        .image-desktop {
            @include grid-column(1, 14);
        }
        .image-mobile {
            @include grid-column(10, 2);
            @include grid-justify-self(start);
            @include grid-align-self(stretch);
            @include breakout($mobile-image-width) {
                width: $value;
            }
            border-bottom: none;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            margin-top: $baseline * 2;
            &::before {
                display: none;
            }
        }
        .text-group {
            @include grid-column(2, 8);
            margin-top: $baseline * 3;
            margin-bottom: $baseline * 2;
        }
        @include breakpoint(460px up) {
            .text-group {
                @include grid-column(3, 7);
            }
        }
        @include breakpoint(medium up) {
            @include subgrid14(1, 7);
            &:nth-child(even) {
                @include subgrid14(8, 7);
            }
            &:nth-child(odd):last-child {
                @include grid14($columns: 6);
            }
            .text-group {
                @include grid-column(2, 4);
            }
            .image-mobile {
                @include grid-column(6, 1);
            }
        }
        @include breakpoint(large up) {
            .text-group {
                // @include grid-column(2, 4);
                margin-top: $baseline * 5;
                margin-bottom: $baseline * 3;
            }
            .image-mobile {
                // @include grid-column(5, 1);
            }
        }
        @include breakpoint(xlarge up) {
            .text-group {
                @include grid-column(2, 3);
                // margin-top: $baseline * 4;
                // margin-bottom: $baseline * 3;
            }
            .image-mobile {
                @include grid-column(5, 1);
            }
            &:nth-child(odd) {
                .text-group {
                    @include grid-column(3, 3);
                }
                .image-mobile {
                    @include grid-column(6, 1);
                }
            }
        }
        @include breakpoint(xxlarge up) {
            .text-group {
                margin-top: $baseline * 6;
                margin-bottom: $baseline * 4;
            }
        }
    }
}
@mixin projects-featured-item-full(
    $height: (
        small: (460/$em) * 1rem,
        xlarge: (640/$em) * 1rem,
        // xxlarge: (720/$em) * 1rem
    ),
    $mobile-image-width: (
        small: 200px,
        medium: 240px,
        large: 280px,
        xlarge: 320px
    )
) {
    @include projects-featured-item-base;
    .item {
        @include grid;
        @include grid14;
        @include breakout($height) {
            @include grid-template-rows(minmax(#{$value}, 1fr));
        }
        & > * {
            @include grid-row(1);
        }
        &::before {
            @include grid-row(1);
            @include grid-column(1, 14);
        }
        .image-desktop {
            @include grid-column(1, 14);
        }
        .image-mobile {
            @include grid-column(10, 4);
            @include grid-justify-self(start);
            @include grid-align-self(start);
            @include breakout($mobile-image-width) {
                width: $value;
            }
            margin-top: $baseline * 2;
            @include breakpoint(medium up) {
                @include grid-justify-self(center);
                @include grid-align-self(stretch);
                border-bottom: none;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                &::before {
                    display: none;
                }
            }
            @include breakpoint(large up) {
                @include grid-column(9, 4);
                margin-top: $baseline * 4;
            }
            @include breakpoint(xlarge up) {
                margin-top: $baseline * 6;
            }
            @include breakpoint(xxlarge up) {
                margin-top: $baseline * 8;
            }
        }
        .text-group {
            @include grid-column(1, 8, true);
            margin-top: $baseline * 4;
            margin-bottom: $baseline * 3;
            @include breakpoint(medium up) {
                margin-top: $baseline * 6;
                margin-bottom: $baseline * 4;
                @include grid-column(1, 6, true);
            }
            @include breakpoint(large up) {
                @include grid-column(2, 5, true);
                margin-top: $baseline * 8;
            }
            @include breakpoint(xlarge up) {
                @include grid-column(2, 5, true);
                margin-top: $baseline * 10;
            }
            @include breakpoint(xxlarge up) {
                @include grid-column(3, 4, true);
                margin-top: $baseline * 12;
            }
        }
    }
    .item:nth-child(even) {
        .image-mobile {
            @include breakpoint(medium up) {
                @include grid-column(2, 4);
            }
            @include breakpoint(large up) {
                @include grid-column(2, 4, true);
            }
        }
        .text-group {
            @include breakpoint(medium up) {
                @include grid-column(7, 6, true);
            }
            @include breakpoint(large up) {
                @include grid-column(8, 5, true);
            }
            @include breakpoint(xlarge up) {
                @include grid-column(7, 5, true);
            }
            @include breakpoint(xxlarge up) {
                @include grid-column(7, 4, true);
            }
        }
    }
    .text-group {
        .title {
            @include typography(header-a);
        }
        .summary {
            @include typography(text-large);
        }
    }

    //visibility effects
    .item {
        .text-group {
            opacity: 0;
            transition: opacity .2s;
        }
        .image-mobile {
            transform: translateX(50%);
            opacity: 0;
            transition: transform .4s ease,
                        opacity .4s ease;
            @include breakpoint(medium up) {
                transform: translateY(10%);
            }
        }
        &.visible {
            .text-group {
                opacity: 1;
            }
            .image-mobile {
                opacity: 1;
                transform: none;
            }
        }
    }
}

@mixin projects-basic-item {
    .item {
        display: flex;
        flex-direction: column;
        position: relative;
        // background-color: $blue;
    }
    .image,
    .text-group {
        // position: absolute;
        // top: 0;
        // left: 0;
        // right: 0;
        // bottom: 0;
    }
    .image {
        @include object-fit((object-fit: contain));
        position: relative;
        pointer-events: none;
        transform-origin: center bottom;
        transition: transform .3s ease;
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        &::before {
            content: '';
            display: block;
            width: 100%;
            padding-bottom: percentage(3/5);
        }
    }
    .image-mobile {
        display: none;
    }
    .text-group {
        margin-top: $baseline * 1.5;
        text-align: center;
        // color: $text-color-white;
        // background-color: rgba($blue, .8);
        // opacity: 0;
        // transition: opacity .3s;
        .title {
            @include typography(header-d);
            font-weight: $medium;
        }
        @include breakpoint(xlarge up) {
            margin-top: $baseline * 2;
        }
    }
    a.item:hover,
    a.item:focus {
        .image {
            transform: scale(1.05);
        }
    }

    .item:nth-child(4n + 1) {
        // background-color: $orange;
        .text-group {
            // background-color: rgba($orange, .8);
        }
    }
    .item:nth-child(4n + 2) {
        // background-color: $blue;
        .text-group {
            // background-color: rgba($blue, .8);
        }
    }
    .item:nth-child(4n + 3) {
        // background-color: $green;
        .text-group {
            // background-color: rgba($green, .8);
        }
    }
    .item:nth-child(4n + 4) {
        // background-color: $purple;
        .text-group {
            // background-color: rgba($purple, .8);
        }
    }
}

$project-slideshow-columns: (
    small: 2 12,
    medium: 8 6,
    xxlarge: 8 5
);
@mixin page-layout-project {
    .header-banner {
        .page_image img {
            filter: blur(4px);
        }
        .group .description {
            max-width: none;
        }
    }
    .primary {
        @include make-grid-rows(
            '.page'
            '.slideshow'
            '.project-details'
            '.pager.url'
            '.callouts-alternating'
            '.pagination-page'
        );
        .page {
            // @include breakout($header-columns) {
            //     // -ms-grid-column: nth($value, 1) + 1;
            //     // grid-column-start: nth($value, 1) + 1; 
            //     @include grid-column(nth($value, 1), nth($value, 2), true);
            // }
            @include breakpoint(smallplus down) {
                @include header-columns;
            }
            @include breakout($header-columns, medium) {
                -ms-grid-column-span: 7 - nth($value, 1);
                grid-column-end: span 7 - nth($value, 1); 
            }
        }
        .pager {
            max-width: 40ch;
            & > .item-wrapper {
                padding-bottom: $baseline / 2;
                border-bottom: 2px solid;
                width: -moz-fit-content;
                width: fit-content;
                @include breakpoint(xlarge up) {
                    padding-bottom: $baseline * 3/4;
                }
            }
            &:nth-child(4n + 1) > .item-wrapper {
                border-color: $orange;
            }
            &:nth-child(4n + 2) > .item-wrapper {
                border-color: $blue;
            }
            &:nth-child(4n + 3) > .item-wrapper {
                border-color: $green;
            }
            &:nth-child(4n + 4) > .item-wrapper {
                border-color: $purple;
            }
        }
        .project-slideshow,
        .pagination-page {
            @include breakout($project-slideshow-columns) {
                @include grid-column(nth($value, 1), nth($value, 2));
            }
            @include breakout($content-gutters) {
                margin-left: $value;
                margin-right: $value;
            }
        }
        .callouts-alternating,
        .project-slideshow {
            @include breakout($section-padding) {
                margin-top: $value;
            }
        }
        .pager.url {
            // @include grid-row(4);
            @include breakout($content-gutters) {
                padding-left: $value;
                padding-right: $value;
            }
            @include breakout($project-slideshow-columns, medium) {
                @include grid-column(nth($value, 1), nth($value, 2));
            }
            @include grid-justify-self(center);
            margin-top: $baseline * 2;
        }
        .project-details {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            & > * {
                flex-basis: 32ch;
                flex-grow: 1;
                margin-top: $baseline * 2;
                @include breakout($content-gutters) {
                    padding-left: $value;
                    padding-right: $value;
                }
                @include breakpoint(medium up) {
                    max-width: none;
                }
                @include breakpoint(xlarge up) {
                    margin-top: $baseline * 2.5;
                }
                & > * {
                    max-width: 40ch;
                }
            }
        }
        .pagination-page {
            // @include grid-row(5);
            z-index: 29;
            @include grid-justify-self(center);
            @include breakout($section-padding) {
                margin-top: $value / 2;
                margin-bottom: $value / 2;
            }
            positin: -webkit-sticky;
            position: sticky;
            bottom: $baseline * 6;
            @include breakpoint(medium up) {
                bottom: $baseline * 3;
            }
            @include breakpoint(xlarge up) {
                bottom: $baseline * 4;
            }
            .pagination-button-list {
                background-color: $content-background-color;
                border-radius: 1.5rem;
                padding: 0 .5rem;
                box-shadow: 0 2px 8px rgba(0,0,0,0.2);
                a,
                span {
                    display: block;
                    padding: .2rem;
                }
            }
            .previous {
                border-color: $light-gray;
            }

            opacity: 0;
            pointer-events: none;
            transition: opacity .3s .1s ease;
            &.active {
                opacity: 1;
                pointer-events: auto;
            }
        }
        @include breakpoint(medium up) {
            @include grid-template-rows(auto 1fr auto auto);
            .page {
                @include grid-row(1, 2);
            }
            .project-slideshow {
                @include grid-row(1);
            }
            .pager.url {
                @include grid-row(2);
                @include grid-justify-self(end);
                z-index: 6;
                text-align: right;
                margin-top: $baseline * -2;
            }
            .project-details {
                @include grid-row(3);
            }
            .callouts-alternating {
                @include grid-row(4);
            }
            .pagination-page {
                @include grid-row(5);
                @include grid-justify-self(right);
            }
        }
        
        .pager.client .field-client {
            @include typography(text-xlarge);
        }
    }
}
// %slide-nav-arrow {
//     height: $baseline * 2;
//     width: $baseline * 2;
//     margin: $baseline;
//     border-top: $baseline / 3 solid;
//     border-right: $baseline / 3 solid;
//     transform: rotate(45deg);
//     opacity: .75;
// }
// @mixin project-slideshow(
//     $height: (
//         small: (320/$em) * 1rem,
//         xlarge: (400/$em) * 1rem,
//         xxlarge: (480/$em) * 1rem
//     )
// ) {
//     position: relative;
//     .image {
//         @include object-fit((
//             object-fit: contain,
//             object-position: center top,
//             height: 100%
//         ));
//         display: flex;
//         background-color: $content-background-color;
//         // @include breakout($height) {
//         //     height: $value;
//         // }
//     }
//     .slideshow-navigation {
//         @include project-slideshow-navigation-pips;
//         margin-top: $baseline;
//         @include breakpoint(medium up) {
//             margin-top: $baseline * 1.5;
//             position: absolute;
//             top: 100%;
//             left: 0;
//             max-width: 70%;
//         }
//     }
// }
// @mixin project-slideshow-navigation-pips {
//     pointer-events: none;
//     ul {
//         display: flex;
//         flex-wrap: wrap;
//     }
//     li {
//         box-sizing: content-box;
//         pointer-events: auto;
//         cursor: pointer;
//         width: 2em;
//         height: (2/$em) * 1em;
//         background-color: $light-gray;
//         background-clip: content-box;
//         padding-top: $baseline;
//         padding-bottom: $baseline;
//         margin-right: (4/$em) * 1em;
//         transition: background-color .3s;
//         &.current-slide {
//             background-color: $anchor-color;
//         }
//         &:hover,
//         &:focus {
//             background-color: $anchor-color-hover;
//         }
//     }
// }

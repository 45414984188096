//config
@import 'site:variables';
@import 'site:icon-mixins';

//mixins
@import '@depot/utility';
@import '@depot/grid';
@import '@depot/flex-columns';
@import '@depot/breakpoint';
@import '@depot/typography';
@import 'slideshow:mixins';
@import 'forms:mixins';
@import 'site:page-mixins';
@import 'site:item-mixins';

@import 'mixins';

.layout-callouts-page {
    @include page-layout-default;
    .callouts-points.lister {
        padding: 0;
    }
}

.callouts-points,
.callouts-clients {
    @include breakout($section-padding) {
        &.lister {
            padding-top: $value;
            padding-bottom: $value;
        }
    }
}

.lister.callouts-points {
    @include callouts-points;
    @include callouts-points-item;
    @include breakpoint(xxlarge up) {
        @include grid-column(3, 9, true);
    }
}

.lister.callouts-clients {
    @include callouts-clients;
    @include callouts-clients-item;
}

.lister.callouts-testimonials {
    @include callouts-testimonials;
    @include callouts-testimonials-item;
}

.lister.callouts-alternating {
    // $image-height: (
    //     small: $baseline * 12,
    //     medium: auto
    // );
    // $columns: 4 7;
    // $flip-columns: 7 4;


    $image-height: (
        small: $baseline * 15,
        smallplus: $baseline * 20,
        medium: $baseline * 35
    );
    $columns: 6 5;
    $flip-columns: 5 6;

    @include breakout($content-gutters) {
        margin-left: $value;
        margin-right: $value;
    }

    @include callout-svg-palettes;
    
    @include breakpoint(xxlarge up) {
        @include grid-column(2, 10, true);
    }

    @include callouts-alternating(
        $columns: $columns,
        $flip-columns: $flip-columns,
        // $item-grid-selector: '&:not(.media):not(.slide)'
    );

    .item {
        // @include callouts-alternating-item(
        //     // $columns: $columns,
        //     $image-height: $image-height,
        // );
        // #{$item-grid-selector} {
        //     @include breakpoint(medium up) {
        //         @include callouts-alternating-item-grid();
        //     }
        // }
        position: relative;
        // &:nth-child(even) {
        //     @include callouts-alternating-item-flip($flip-columns);
        // }
        .head-image {
            justify-content: center;
        }
        .image {
            @include object-fit((object-fit: contain));
            flex-grow: 0;
            @include breakpoint(medium up) {
                grid-row: 2;
                max-height: $baseline * 30;
            }
        }
        @include breakpoint(medium up) {
            .head-image ~ .text-group {
                @include grid-align-self(center);
            }
        }
    }
    .item.slideshow {
        // @include callouts-alternating-item(
        //     $columns: $slideshow-columns,
        //     $image-height: $slideshow-image-height,
        // );
        // &:nth-child(even) {
        //     @include callouts-alternating-item-flip($slideshow-flip-columns);
        // }
        // .image {
        //     @include object-fit((object-fit: contain));
        // }
        @include content-slideshow($image-height);
    }

    .item:not(:first-child) {
        @include breakout($section-padding) {
            margin-top: $value;
        }
    }
    .item .item {
        margin-top: 0;
    }
    .slideshow {
    }
}

@import 'parent:';

$breakpoints: (
  small: 0,
  smallplus: 400px,
  medium: 768px,
  large: 1020px,
  xlarge: 1400px,
  xxlarge: 1700px,
);

$mobile-breakpoint-up: medium;
$mobile-breakpoint-down: smallplus;

$outer-gutters: (
    small: $gutter * 1.5,
    medium: $gutter * 4,
    // large: $gutter * 6,
    xlarge: $gutter * 6,
    xxlarge: $gutter * 8
);

$content-gutters: (
    small: $gutter / 2,
    large: $gutter
);

$section-padding: (
    small: $baseline * 4,
    medium: $baseline * 5,
    xlarge: $baseline * 8,
    // xxlarge: $baseline * 10
);

$banner-height: (
    small: 18rem,
    xlarge: (480/$em) * 1rem
    // xxlarge: (640/$em) * 1rem
);
$banner-height-home: (
    small: 18rem,
    large: (480/$em) * 1rem,
    xlarge: (640/$em) * 1rem
    // xxlarge: (720/$em) * 1rem
);
$banner-clip-path: polygon(
    0 0,
    100% 0,
    100% calc(100% - var(--tail-height)),
    calc(var(--tail-offset) + var(--tail-height)) calc(100% - var(--tail-height)),
    var(--tail-offset) 100%,
    var(--tail-offset) calc(100% - var(--tail-height)),
    0 calc(100% - var(--tail-height))
);
$banner-clip-ratio: 6;

$site-width: 100vw;
$site-columns: 12;

$blue: #488BC9;
$blue-dark: #315F89;

$green-light: #E6EAB4;
$green: #B0B949;
$green-dark: #939b38;
$green-logo: #B3BE31;

$orange: #E58D53;
$purple: #9686B5;

$off-white: #F8F8EC;
$light-gray: #D7D8D8;
$medium-gray: #676767;
$dark-gray: #474747;

$alert-color: #ff7959;
$required-color: #ff562d;

$primary-color: $green;

$secondary-color: $blue;

$text-color-regular: $medium-gray;
$text-color-dark: $dark-gray;

$anchor-color: $blue;
$anchor-color-hover: $blue-dark;

$anchor-color-white-hover: $green-light;

$header-text-color: $text-color-regular;
$header-background-color: $green-light;
$header-background-blend-mode: multiply;
$header-background-filter: grayscale(1);
$header-background-opacity: .2;
$content-background-color: $white;
$footer-background-color: $dark-gray;
$footer-contact-background-color: $blue;

$body-font-family: 'Raleway', sans-serif;
$header-font-family: 'Raleway', sans-serif;

$light: 300;
$normal: 400;
$medium: 500;
$semibold: 600;
$bold: 700;

$available-icons: facebook twitter email phone instagram;

$input-props: (
    vertical-margin: $baseline * 2,
    // input-background-color: $medium-gray,
    // text-color: $text-color-white,
    // placeholder-text-color: $light-gray,
    input-border-radius: (2/$em) * 1rem,
    input-line-height: 1.25em,
    input-padding: .625em 1em,
    input-font-size: (18/$em) * 1rem,
    input-border: 0,
    label-x-offset: 1em,
    button-min-width: 8em
);

$button-colors: (
    base: (
        background: $blue,
        text: $text-color-white,
        border: $blue,
        background-hover: $blue,
        text-hover: $text-color-white,
        border-hover: $blue,
    ),
    fill: (
        background: $blue,
        text: $text-color-white,
        border: $blue,
        background-hover: $blue,
        text-hover: $text-color-white,
        border-hover: $blue,
    ),
    text-only: (
        background: transparent,
        text: inherit,
        border: transparent,
        background-hover: transparent,
        text-hover: $anchor-color,
        border-hover: transparent,
    ),
    white: (
        background: $white,
        text: $anchor-color,
        border: $white,
        background-hover: $white,
        text-hover: $anchor-color,
        border-hover: $white,
    ),
);

$typography: (
    header-base: (
        font-weight: $light,
        font-family: $header-font-family,
        line-height: 1.25,
        color: inherit,
    ),
    text-base: (
        font-weight: $normal,
        line-height: 1.5,
        font-family: $body-font-family,
        color: $text-color-regular,
    ),
    header-e: (
        base: header-base,
        font-size: #{12/$em}rem,
        line-height: #{16/$em}rem,
        font-weight: $normal,
        xlarge: (
            font-size: #{14/$em}rem,
            line-height: #{18/$em}rem,
        ),
    ),
    header-d: (
        base: header-base,
        font-size: #{18/$em}rem,
        line-height: #{24/$em}rem,
        xlarge: (
            font-size: #{22/$em}rem,
            line-height: #{28/$em}rem,
        ),
    ),
    header-c: (
        base: header-base,
        font-size: #{22/$em}rem,
        line-height: #{28/$em}rem,
        xlarge: (
            font-size: #{28/$em}rem,
            line-height: #{36/$em}rem,
        ),
    ),
    header-b: (
        base: header-base,
        font-size: #{24/$em}rem,
        line-height: #{28/$em}rem,
        xlarge: (
            font-size: #{30/$em}rem,
            line-height: #{36/$em}rem,
        ),
    ),
    header-a: (
        base: header-base,
        font-size: #{30/$em}rem,
        line-height: #{36/$em}rem,
        xlarge: (
            font-size: #{48/$em}rem,
            line-height: #{60/$em}rem,
        ),
    ),
    text-small: (
        base: text-base,
        font-size: #{12/$em}rem,
        line-height: #{18/$em}rem,
        xlarge: (
            font-size: #{14/$em}rem,
            line-height: #{20/$em}rem,
        )
    ),
    text-medium: (
        base: text-base,
        font-size: #{16/$em}rem,
        line-height: 1.5,
        xlarge: (
            font-size: #{18/$em}rem,
            // line-height: #{24/$em}rem,
        ),
    ),
    text-large: (
        base: text-base,
        color: inherit,
        font-size: #{16/$em}rem,
        line-height: #{30/$em}rem,
        smallplus: (
            font-size: #{18/$em}rem,
        ),
        xlarge: (
            font-size: #{24/$em}rem,
            line-height: #{42/$em}rem,
        ),
    ),
    text-xlarge: (
        base: text-base,
        color: inherit,
        font-size: #{18/$em}rem,
        line-height: #{30/$em}rem,
        xlarge: (
            font-size: #{30/$em}rem,
            line-height: #{48/$em}rem,
        ),
    ),
);

$article-props: (
    gutter: $baseline * 2,
    compact-breakpoint: small,
    content-width: 65ch,
);

$article-icons-props: (
    size: 1em,
    background-size: 1em,
    selector: ".head .title::after",
);

$email-button-palette: base;
$email-button-props: (
    padding: $baseline $baseline * 2
);

.group {
    & > * {
        margin-top: $baseline * 2;
        @include breakpoint(xlarge up) {
            margin-top: $baseline * 2.5;
        }
        &:first-child {
            margin-top: 0;
        }
    }
    .logo {
        img {
            height: #{36/$em}rem;
            @include breakpoint(xlarge up) {
                height: #{60/$em}rem;
            }
        }
    }
    .title {
        max-width: 30ch;
    }
    .description {
        @include typography(text-large);
    }
    .button-cont {
        margin-top: $baseline * 2.5;
        @include breakpoint(xlarge up) {
            margin-top: $baseline * 3;
        }
    }
}

.group.page {
    @include breakout($section-padding) {
        margin-top: $value;
    }
    @include breakout($content-gutters) {
        & > * {
            margin-left: $value;
            margin-right: $value;
        }
    }
}

.group > .item-wrapper,
.lister > .item-wrapper {
    & > * {
        margin-top: $baseline;
        @include breakpoint(xlarge up) {
            margin-top: $baseline * 2;
        }
        &:first-child {
            margin-top: 0;
        }
    }
    .title {
        @include typography(header-a);
        max-width: 30ch;
    }
    .description {
        @include typography(text-large);
        max-width: 60ch;
    }
}

.pager > .item-wrapper {
    .title {
        @include typography(header-d);
        max-width: 30ch;
    }
    .description {
        max-width: 30ch;
        margin-top: $baseline / 2;
        @include breakpoint(xlarge up) {
            margin-top: $baseline;
        }
    }
    margin-bottom: $baseline;
    @include breakpoint(xlarge up) {
        margin-bottom: $baseline * 1.5;
    }
}

.item-group.pagination-page {
    @include pagination;
}

.header-banner {
    @include hero-banner;
}
.layout-home-home {
    .group .description {
        // max-width: 30ch;
    }
    .header-banner {
        @include hero-banner-height($banner-height-home);
    }
}

.button-cont.contact-fab {
    @include contact-fab;
}

.item-group.sitemap {
    @include sitemap-basic;
    @include breakout($section-padding) {
        padding-top: $value;
        padding-bottom: $value;
    }
}

// .lister.callouts-alternating .slideshow {
//     // @include slideshow;

//     .image {
//         @include grid-row(1);
//         @include grid-column(1);
//         @include object-fit;

//         display: flex;
//         top: 0;
//         left: 0;
//         width: 100%;
//         height: 100%;

//         /* &:last-child { */
//         /*     @include grid-row(1,2); */
//         /*     @include breakpoint(medium up) { */
//         /*         @include grid-row(1); */
//         /*     } */
//         /* } */
//     }
// }

html {
    scroll-behavior: smooth;
    // background-color: $dark-gray;
}

.content {
    flex-grow: 1;
    background-color: $content-background-color;
}

@include page-layout-base;

.layout-not-found-page {
    .item-group.sitemap {
        display: none;
    }
}

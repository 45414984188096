.footer {
    @include grid;
    @include grid14;
    color: $text-color-white;
    background-color: $footer-background-color;
    box-shadow: 0 20rem $footer-background-color;
    & > * {
        @include grid-column(1, 12, true);
        @include breakout($content-gutters) {
            padding-left: $value;
            padding-right: $value;
        }
        @include breakout($section-padding) {
            margin-top: $value * 2/3;
            margin-bottom: $value * 2/3;
        }
    }
    a {
        color: $text-color-white;
        &:hover,
        &:focus {
            color: $anchor-color-white-hover;
        }
    }
    .title{
        @include typography(header-b);
        .sb-logo-minimal {
            margin-left: calc(#{$baseline * 1.5} - .5em);
            margin-right: calc(#{$baseline / 2} + #{$baseline * 1.5} - .5em);
            @include breakpoint(xlarge up) {
                margin-left: calc(#{$baseline * 2} - .5em);
                margin-right: calc(#{$baseline} + #{$baseline * 2} - .5em);
            }
        }
        text-align: center;
    }
    .footer-contact-details {
        @include grid-row(1);
        @include grid-justify-self(center);
        max-width: 18em;
        width: 100%;
    }
    .contact_form {
        @include grid-row(2);
    }
    .footer-privacy {
        @include grid-row(2);
        @include grid-justify-self(end);
        @include grid-align-self(end);
        position: relative;
        z-index: 2;
        margin-top: 0;
        pointer-events: none;
        a {
            $button-padding: map-get(get-input-props((), button-base), button-padding);
            display: inline-block;
            padding-top: get-side($button-padding, top);
            padding-bottom: get-side($button-padding, top);
            pointer-events: auto;
        }
    }
    .footer-copyright {
        @include grid-row(3);
        margin-top: 0;
        text-align: center;
    }
    &::before {
        @include grid-row(1);
        @include grid-column(1, 14);
        @include grid-justify-self(stretch);
        @include grid-align-self(stretch);
        content: '';
        display: block;
        background-color: $footer-contact-background-color;
    }
    @include breakpoint(medium up) {
        .title {
            text-align: left;
        }
        .footer-contact-details {
            @include grid-column(1, 5, true);
        }
        .contact_form,
        .footer-privacy {
            @include grid-row(1, 2);
            @include grid-column(7, 6, true);
        }
        .footer-copyright {
            @include grid-row(2);
            @include grid-column(1, 5, true);
            @include grid-justify-self(center);
            @include grid-align-self(end);
            text-align: left;
            max-width: 18em;
            width: 100%;
        }
        &::before {
            @include grid-row(1, 2);
            @include grid-column(1, 6);
            box-shadow: 0 20rem $footer-contact-background-color;
        }
    }
    @include breakpoint(xlarge up) {
        .footer-contact-details,
        .footer-copyright {
            @include grid-justify-self(start);
            @include grid-column(2, 4, true);
        }
        .contact_form,
        .footer-privacy {
            @include grid-column(7, 5, true);
        }
    }
    @include breakpoint(xxlarge up) {
        .footer-contact-details,
        .footer-copyright {
            @include grid-column(3, 3, true);
        }
        .contact_form,
        .footer-privacy {
            @include grid-column(7, 4, true);
        }
    }
}

.footer-contact-details {
    @include grid;
    @include grid-template-columns(1fr auto);
    @include grid-template-rows(auto 1fr);
    @include icon-size($size: $baseline * 3, $background-size: 1em);
    font-size: #{18/$em}rem;
    line-height: $baseline * 2;
    @include breakpoint(xlarge up) {
        font-size: #{20/$em}rem;
    }
    .title {
        @include grid-row(1);
        @include grid-column(1, 2);
    }
    .contact-list {
        @include grid-row(2);
        @include grid-column(1);
        margin-top: $baseline;
        li {
            display: flex;
            margin-top: $baseline * 1.5;
        }
        .icon a {
            &::before {
                margin-right: $baseline / 2;
            }
        }
    }
    .contact-social {
        @include grid-row(2);
        @include grid-column(2);
        margin-top: $baseline * 1.5;
        .icon a {
            &::before,
            &::after {
                background-size: $baseline * 2 $baseline * 2;
            }
        }
        li {
            display: flex;
            margin-top: $baseline / 2;
        }
    }
    @include breakpoint(medium up) {
        display: block;
        .contact-social {
            margin-top: $baseline * 1.5;
            li {
                display: inline-flex;
                margin-right: $baseline;
            }
        }
    }
    @include breakpoint(xlarge up) {
        @include icon-size($size: $baseline * 4, $background-size: 1em);
        .contact-list {
            li {
                margin-top: $baseline * 2;
            }
            .icon a {
                &::before {
                    margin-right: $baseline;
                }
            }
        }
        .contact-social {
            margin-top: $baseline * 2.5;
            .icon a {
                &::before,
                &::after {
                    background-size: $baseline * 2.5 $baseline * 2.5;
                }
            }
        }
    }
}

@include rte-content($baseline: $baseline * 1.5, $list-baseline: $baseline * 2/3);
@include base-content();
@include rte-lists($gutter: .75em, $bullet-style-placeholder: '%rte-bullet');
%rte-bullet {
    width: .75em;
    color: $green;
    font-weight: $bold;
    transform: scale(1.25);
}
a {
    transition: color .2s;
}
.rte a {
    text-decoration: underline;
}

h2.rte {
    @include typography(header-c);
}
h3.rte {
    @include typography(header-d);
    padding-top: (4/$em) * 1rem;
    color: $green-dark;
    @include breakpoint(xlarge up) {
        padding-top: (8/$em) * 1rem;
    }
}

.rte.small-text {
    // @include typography(text-small);
    font-size: .8em;
    line-height: 1.75;
}

.header {
    @include grid;
    @include grid14;
    z-index: 29;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.primary-navigation {
    @include grid-column(1, 12, true);
    @include breakout($content-gutters, smallplus) {
        margin-left: $value;
        margin-right: $value;
    }
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: $baseline * 1.5 0;

    font-size: (14/$em) * 1rem;
    font-weight: $medium;
    line-height: $baseline * 2;

    a {
        color: $text-color-regular;
        &:hover,
        &:focus {
            color: $anchor-color-hover;
        }
    }
    .is-active > a {
        color: $anchor-color;
        &:hover,
        &:focus {
            color: $anchor-color-hover;
        }
    }

    .sb-logo-minimal {
        height: 1.5em;
        width: 1.5em;
        margin-bottom: -.5em; 
    }
    .nav-primary {
        display: flex;
        li {
            margin-left: $baseline / 2;
            margin-right: -.5rem;
        }
        a {
            padding: $baseline .5rem;
        }
    }

    @include breakpoint(medium up) {
        @include grid-justify-self(end);
        justify-content: flex-end;
        font-size: (18/$em) * 1rem;
        padding: $baseline * 2 0;
        // line-height: $baseline * 3;

        .nav-primary {
            li {
                margin-left: $baseline * 1.5;
            }
        }
    }
}


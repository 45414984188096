@import 'site:variables';
@import 'site:icon-mixins';

@import '@depot/breakpoint';
@import '@depot/typography';

@import 'mixins';

@include form-base(
    // $text-color: $text-color-white,
    $border-color: map-get(get-input-props((), input-colors), input-border-color),
    $border-focus-color: map-get(get-input-props((), input-colors), input-border-focus-color)   
);

.site {
    @include input-all(
        $base-style: input-label-absolute
    );
    .input {
        #{text-inputs()},
        select,
        textarea {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            box-shadow: 0 (2/$em) * 1em 0 $light-gray;
            transition: box-shadow .2s;
            &:focus,
            &.has-value {
                box-shadow: 0 (2/$em) * 1em 0 $anchor-color;
            }
            &.user-error {
                box-shadow: 0 (2/$em) * 1em 0 $alert-color;
            }
        }
    }
    .button {
        @include button;
    }
    .button.text-only {
        @include button-reset;
        @include button-text-only;
    }
    .button-contact {
        &::before {
            @include sb-icon-chat($text-color-white);
            content: '';
            display: inline-block;
            width: 1em;
            height: 1em;
            margin-right: .4em;
            margin-bottom: -.2em;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
        }
    }

    .sb-admin {
        #{text-inputs()},
        select,
        textarea {
            border: 1px solid;
        }
    }

}

.contact-form {
    .button {
        margin-top: $baseline * 2;
    }
    .recaptcha-custom-terms {
        font-size: (11/$em) * 1rem;
        margin-top: $baseline * 1.5;
        color: $light-gray;
    }
}

//config
@import 'site:variables';
@import 'site:icon-mixins';

//mixins
@import '@depot/utility';
@import '@depot/grid';
@import '@depot/flex-columns';
@import '@depot/breakpoint';
@import '@depot/typography';
@import 'forms:mixins';

@import 'callouts:mixins';
@import 'site:page-mixins';
@import 'mixins';

.layout-articles-list,
.layout-articles-page {
    @include page-layout-default;
}

.layout-articles-list {
    @include breakpoint(large up) {
        .pager.primary-image {
            @include grid-row(1);
            @include grid-column(8, 5, true);
        }
        .article-list {
            @include grid-row(1);
            @include grid-column(1, 7, true);
            max-width: none;
        }
    }
    @include breakout($section-padding, large) {
        .pager.primary-image,
        .article-list {
            margin-bottom: $value;
        }
    }
}

.pager.primary-image,
.article-list {
    @include grid-justify-self(center);
    width: 100%;
    max-width: $content-width;
    @include breakout($content-gutters) {
        padding-left: $value;
        padding-right: $value;
    }
}

.article-list {
    @include article-icons;
    @include basic-article-list;
    @include callout-svg-palettes;
    .item {
        max-width: -moz-max-content;
        max-width: max-content;
    }
    .text-group {
        display: flex;
        flex-direction: column;
        align-items: start;
        & > * {
            max-width: 100%;
        }
    }
    .head .title {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        color: map-get(get-article-icons-props(), color);
        // border-bottom: 2px solid $text-color-light;
        // padding-bottom: $baseline / 2;
        min-width: 8ch;
    }
    .item:not(.none) .head .title::after {
        display: inline-block;
    }
    .head .title::after {
        margin-left: .5em;
        transform: translateY(.125em);
        flex-shrink: 0;
    }
    a.head-link:hover,
    a.head-link:focus {
        .head .title {
            color: map-get(get-article-icons-props(), hover-color);
        }
    }
    .head .summary {
        @include typography(text-medium);
    }
    @include breakpoint(map-get(get-article-props(), compact-breakpoint) down) {
        .has-image .head .title {
            grid-column: 2 / span 2;
        }
    }
}

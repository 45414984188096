@import 'parent:';

@mixin sb-icon-facebook($svg-color) {
    background-image: inline-svg('<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 510 510" style="fill:#{$svg-color};"><path d="M459,0H51C22.95,0,0,22.95,0,51v408c0,28.05,22.95,51,51,51h408c28.05,0,51-22.95,51-51V51C510,22.95,487.05,0,459,0z M433.5,51v76.5h-51c-15.3,0-25.5,10.2-25.5,25.5v51h76.5v76.5H357V459h-76.5V280.5h-51V204h51v-63.75C280.5,91.8,321.3,51,369.75,51H433.5z"/></svg>');
}
@mixin sb-icon-twitter($svg-color) {
    background-image: inline-svg('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 510 510" style="fill: #{$svg-color};"><path d="M459,0H51C22.95,0,0,22.95,0,51v408c0,28.05,22.95,51,51,51h408c28.05,0,51-22.95,51-51V51C510,22.95,487.05,0,459,0zM400.35,186.15c-2.55,117.3-76.5,198.9-188.7,204C165.75,392.7,132.6,377.4,102,359.55c33.15,5.101,76.5-7.649,99.45-28.05c-33.15-2.55-53.55-20.4-63.75-48.45c10.2,2.55,20.4,0,28.05,0c-30.6-10.2-51-28.05-53.55-68.85c7.65,5.1,17.85,7.65,28.05,7.65c-22.95-12.75-38.25-61.2-20.4-91.8c33.15,35.7,73.95,66.3,140.25,71.4c-17.85-71.4,79.051-109.65,117.301-61.2c17.85-2.55,30.6-10.2,43.35-15.3c-5.1,17.85-15.3,28.05-28.05,38.25c12.75-2.55,25.5-5.1,35.7-10.2C425.85,165.75,413.1,175.95,400.35,186.15z"/></svg>');
}
@mixin sb-icon-instagram($svg-color) {
    background-image: inline-svg('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 510 510" style="fill: #{$svg-color};"><path d="M340.3,112.8H169.7c-31.4,0-56.9,25.5-56.9,56.9v170.7c0,31.4,25.5,56.9,56.9,56.9h170.7c31.4,0,56.9-25.5,56.9-56.9V169.7C397.2,138.3,371.7,112.8,340.3,112.8z M255,340.3c-47.1,0-85.3-38.2-85.3-85.3c0-47.1,38.2-85.3,85.3-85.3c47.1,0,85.3,38.2,85.3,85.3C340.3,302.1,302.1,340.3,255,340.3z M347.5,183.9c-11.8,0-21.3-9.6-21.3-21.3c0-11.8,9.6-21.3,21.3-21.3c11.8,0,21.3,9.6,21.3,21.3C368.8,174.3,359.2,183.9,347.5,183.9z"/><path d="M255,198.1c-31.4,0-56.9,25.5-56.9,56.9s25.5,56.9,56.9,56.9c31.4,0,56.9-25.5,56.9-56.9S286.4,198.1,255,198.1z"/><path d="M459,0H51C23,0,0,23,0,51v408c0,28,23,51,51,51h408c28,0,51-23,51-51V51C510,23,487,0,459,0z M425.7,340.3c0,46.9-38.4,85.3-85.3,85.3H169.7c-46.9,0-85.3-38.4-85.3-85.3V169.7c0-46.9,38.4-85.3,85.3-85.3h170.7c46.9,0,85.3,38.4,85.3,85.3V340.3z"/></svg>');
}

@mixin sb-icon-arrow($svg-color: $primary-color){
    background-image: inline-svg('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" style="fill: #{$svg-color};"><rect x="5.4" y="5.9" transform="matrix(0.7071 0.7071 -0.7071 0.7071 9.1429 -7.3154)" width="16" height="3"/><rect x="11.9" y="8.6" transform="matrix(0.7071 0.7071 -0.7071 0.7071 15.6784 -4.6083)" width="3" height="16"/></svg>');
}
@mixin sb-icon-arrow-left($svg-color: $primary-color){
    background-image: inline-svg('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" style="fill: #{$svg-color}; transform: rotate(180deg); transform-origin: center;"><rect x="5.4" y="5.9" transform="matrix(0.7071 0.7071 -0.7071 0.7071 9.1429 -7.3154)" width="16" height="3"/><rect x="11.9" y="8.6" transform="matrix(0.7071 0.7071 -0.7071 0.7071 15.6784 -4.6083)" width="3" height="16"/></svg>');
}
@mixin sb-icon-arrow-down($svg-color: $primary-color) {
    background-image: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 451.847 451.847" enable-background="new 0 0 451.847 451.847"><path fill="#{$svg-color}" d="M225.923 354.706c-8.098 0-16.195-3.092-22.37-9.263L9.27 151.157c-12.36-12.36-12.36-32.397 0-44.75 12.354-12.355 32.388-12.355 44.748 0L225.923 278.32 397.83 106.413c12.358-12.354 32.39-12.354 44.743 0 12.365 12.354 12.365 32.392 0 44.75L248.293 345.45c-6.178 6.17-14.275 9.256-22.37 9.256z"/></svg>');
}
@mixin sb-icon-arrow-up($svg-color: $primary-color) {
    background-image: inline-svg('<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 451.847 451.847" enable-background="new 0 0 451.847 451.847" style="transform: rotate(180deg); transform-origin: center;"><path fill="#{$svg-color}" d="M225.923 354.706c-8.098 0-16.195-3.092-22.37-9.263L9.27 151.157c-12.36-12.36-12.36-32.397 0-44.75 12.354-12.355 32.388-12.355 44.748 0L225.923 278.32 397.83 106.413c12.358-12.354 32.39-12.354 44.743 0 12.365 12.354 12.365 32.392 0 44.75L248.293 345.45c-6.178 6.17-14.275 9.256-22.37 9.256z"/></svg>');
}

@mixin sb-icon-user($svg-color) {
    background-image: inline-svg('<svg fill="#{$svg-color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36"><path d="M18,.562a18,18,0,1,0,18,18A18,18,0,0,0,18,.562ZM18,7.53a6.387,6.387,0,1,1-6.387,6.387A6.387,6.387,0,0,1,18,7.53ZM18,32.5a13.908,13.908,0,0,1-10.633-4.95,8.092,8.092,0,0,1,7.149-4.34,1.776,1.776,0,0,1,.515.08,9.61,9.61,0,0,0,2.969.5,9.573,9.573,0,0,0,2.969-.5,1.776,1.776,0,0,1,.515-.08,8.092,8.092,0,0,1,7.149,4.34A13.908,13.908,0,0,1,18,32.5Z" transform="translate(0 -0.563)"/></svg>');
}
@mixin sb-icon-logo($svg-color) {
    background-image: inline-svg('<svg fill="#{$svg-color}" class="sb-logo-minimal" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58.419 69.009"><title>Smallbox</title><path d="M43.467,0H14.958A14.973,14.973,0,0,0,0,14.95V37.6A14.964,14.964,0,0,0,14.84,52.539l.038,16.47L30.632,52.547H43.467A14.968,14.968,0,0,0,58.419,37.6V14.95A14.968,14.968,0,0,0,43.467,0Zm4.17,37.6a4.174,4.174,0,0,1-4.17,4.168H29.054l-7.831,9.121.045-9.121h-6.31A4.178,4.178,0,0,1,10.782,37.6V14.95a4.179,4.179,0,0,1,4.176-4.171H43.467a4.175,4.175,0,0,1,4.17,4.171Z"/></svg>');
}
@mixin sb-icon-quote($svg-color) {
    background-image: inline-svg('<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" style="fill:#{$svg-color};"><path d="M17.2,9.7C9.9,9.7,4,15.7,4,22.9s5.9,13.2,13.2,13.2c0.4,0,0.8,0.2,1,0.6c0.4,0.8,1.3,6-3.4,13.4c1.3,1.3,2.1,2.1,2.7,2.8c0.5,0.5,0.8,0.9,1.1,1.2c3-3,13.2-14,11.9-30.9C29.9,15.3,24.3,9.7,17.2,9.7z"/><path d="M59.9,23c-0.6-7.8-6.1-13.4-13.2-13.4c-7.3,0-13.2,5.9-13.2,13.2S39.4,36,46.7,36c0.4,0,0.8,0.2,1,0.6c0.4,0.8,1.3,6-3.4,13.4c1.3,1.3,2.1,2.1,2.7,2.8c0.5,0.5,0.9,0.9,1.2,1.2C51,51,61.2,40,59.9,23L59.9,23z"/></svg>');
}
@mixin sb-icon-chat($svg-color) {
    @include sb-icon-logo($svg-color);
}
